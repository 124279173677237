/************************************
            APP.JS CORE
************************************/
$(document).ready(function () {
    /* MOBILE MENU */
    var mobileMenu = new MobileHamburger('.main-menu', '.mb-hamburger');
    mobileMenu.subscribe();

    /* HOME SLIDER */
    var homeSliderEl = '#home-slider';

    if ( $(homeSliderEl).length > 0 ) {
        var homeSlider = new HomeSlider(homeSliderEl);
        homeSlider.init();
    }

    $('.video-cover-wrapper').length > 0 && videoCoverInit();
    $('#movie-cast-slider').length > 0 && movieCastSliderInit();
    $('#tv-detay-gallery').length > 0 && tvDetailGalleryInit();

});

function MobileHamburger(menu, hamburger) {
    var self = this;

    self.$menu = $(menu);
    self.$hamburger = $(hamburger);

    self.expandMenu = function () {
        self.$hamburger.addClass('active');
        self.$menu.slideDown();
    };

    self.collapseMenu = function () {
        self.$hamburger.removeClass('active');
        self.$menu.slideUp();
    };

    self.subscribe = function () {
        self.$hamburger.on('click', function () {
            if ( $(this).hasClass('active') )
                self.collapseMenu();
            else
                self.expandMenu();
        });
    };
}

function HomeSlider(query) {
    var self = this;
    self.$el = $(query);
    self.$ctrl = self.$el.next('.home-slider-controllers');


    self.options = {
        singleItem: true,
        navigation: false,
        pagination: false,
        addClassActive: true,
        afterMove: function (slider) {
            var $slider = $(slider);

            var index = $slider.find('.active').index();

            $('.slider-title').removeClass('active');
            $('.slider-title.title-' + index).addClass('active');
        }
    };

    self.init = function () {
        self.$el.owlCarousel(self.options);
        self.owl = self.$el.data('owlCarousel');

        self.subscribe();
    };

    self.subscribe = function () {

        self.$ctrl.find('.slider-control').on('click', function () {
            var direction = $(this).attr('data-direction');
            self.move(direction);
        });

    };

    self.move = function (direction) {
        if (direction === 'left')
            self.owl.prev();
        else
            self.owl.next();
    };
}

function videoCoverInit() {
    var $videoButton = $('.video-cover-play-button');

    $videoButton.on('click', function () {
        var $wrapper = $(this).parents('.video-cover-wrapper');
        var videoUrl = $wrapper.attr('data-video-url') + '?autoplay=1';

        $wrapper.addClass('video-show');

        var $iframe = $('<iframe></iframe>');
        $iframe.attr('allowfullscreen', true);
        $iframe.attr('src', videoUrl);

        $wrapper.append($iframe);
    });
}

function movieCastSliderInit() {
    var $slider = $('#movie-cast-slider');

    var $itemWrapper = '';

    var windowWidth = $(window).width();
    wrapSliderItems(windowWidth);

    $slider.owlCarousel({
        navigation: false,
        singleItem: true
    });

    function setItemWrapper() {
        $itemWrapper = $('<div class="item"></div>');
    }

    function appendItemWrapper() {
        $slider.append($itemWrapper);
    }

    function wrapSliderItems(windowWidth) {
        var $dataList = $slider.find('.slider-item-data').clone();

        var limit = (function (windowWidth) {
            if ( windowWidth > 480 ) return 6;
            else return 4;
        })(windowWidth);

        setItemWrapper();
        $slider.empty();

        $dataList.find('li').each(function (index, el) {

            var $item = $('<a class="img-wrapper"><div class="img-self"></div></a>');
            var imageUrl = 'url("'+ $(el).html() +'")';
            var itemLink = $(el).attr('data-url');
            var imageLength = $dataList.find('li').length;

            $item.find('.img-self').css('background-image', imageUrl);
            $item.attr('href', itemLink);
            $itemWrapper.append($item);

            if ( (index + 1) % limit == 0 || index == imageLength - 1) {
                appendItemWrapper();
                setItemWrapper();
            }
        });

        $slider.find('.item').lightGallery({
            thumbnail: false
        });
    }
}

function tvDetailGalleryInit() {
    var $gallery = $('#tv-detay-gallery');

    $gallery.lightGallery();
}
